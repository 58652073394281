// Small
.u-margin-small {
    margin: $spacing-small;
}
.u-margin-small-top {
    margin-top: $spacing-small;
}
.u-margin-small-right {
    margin-right: $spacing-small;
}
.u-margin-small-bottom {
    margin-bottom: $spacing-small;
}
.u-margin-small-left {
    margin-left: $spacing-small;
}

// Medium
.u-margin-medium {
    margin: $spacing-medium;
}
.u-margin-medium-top {
    margin-top: $spacing-medium;
}
.u-margin-medium-right {
    margin-right: $spacing-medium;
}
.u-margin-medium-bottom {
    margin-bottom: $spacing-medium;
}
.u-margin-medium-left {
    margin-left: $spacing-medium;
}

// Large
.u-margin-large {
    margin: $spacing-large;
}
.u-margin-large-top {
    margin-top: $spacing-large;
}
.u-margin-large-right {
    margin-right: $spacing-large;
}
.u-margin-large-bottom {
    margin-bottom: $spacing-large;
}
.u-margin-large-left {
    margin-left: $spacing-large;
}

// PADDING
.u-padding-small-h {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
}
.u-padding-medium-h {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
}
.u-padding-large-h {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;

    @include phone {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.u-padding-small-top {
    padding-top: $spacing-small;
}
.u-padding-medium-top {
    padding-top: $spacing-medium;
}
.u-padding-large-top {
    padding-top: $spacing-large;
}

.u-padding-large-bottom {
    padding-bottom: $spacing-large;
}