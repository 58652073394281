@mixin phone {
    @media screen and (max-width: 767px) {
        @content
    }
}

@mixin tablet {
    @media screen and (max-width: 1023px) {
        @content
    }
}

@mixin laptop {
    @media screen and (max-width: 1279px) {
        @content
    }
}