$thin       : 100;
//$extra-light: 200;
//$book       : 200; 
//$flight     : 300;
$regular    : 400;
$medium     : 500;
//$semibold   : 600;
$bold       : 700;
//$extra-bold : 800;
$black : 900;

$primary     : #30B8C3;
$secondary   : #8378d9;
$dark        : #333;
$light       : #FFF;
$gray        : #696767;
$gray-light  : #edeeef;
$gray-lighter: #fafafe;

$grid-gap : 3rem;

$spacing-small:  2rem;
$spacing-medium: 4rem;
$spacing-large:  50px;

$font-size  : 20px;
$font-color : $dark;
$font-family: 'PF Handbook Pro', sans-serif;