@import 'variables';
@import 'mixins';

.xdebug-var-dump,.xdebug-var-dump *, .xdebug-error, .xdebug-error * {font-size: 16px !important;}


/**************************************
 * TAGS
 **************************************/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    font-size: $font-size;
    color: $font-color;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}
a img {border: none;}

*:focus {outline: 0 !important;}

select {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
}

/**************************************
 * GLOBAL CLASSES
 **************************************/

.dyn-cov {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dyn-con {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

img {
    max-width: 100%;
    height: auto;

    &.alignleft {float: left;clear: both; margin: 15px 15px 15px 0;}
    &.aligncenter {display: block; margin: 0 auto;clear: both;}
    &.alignright {float: right;clear: both; margin: 15px 0 15px 15px;}
}

.tr {
    transition: .2s;
}

.container {
    position: relative;
    width: 1000px;
    margin: 0 auto;
	
	// 1000 + 2 * 1rem
    @media screen and (max-width: 1032px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @include phone {
        padding-left: 14px;
        padding-right: 14px;
    }

    &.m-full {
        @include phone {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.container-sm {
    position: relative;
    width: 776px;
    margin: 0 auto;
	
	// 1024 + 2 * 1rem
    @media screen and (max-width: 808px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.pad {padding: 50px;}

.btn {
    display: inline-block;
    padding: 0.8em 2em;
    min-width: 130px;
    text-align: center;
    font-size: 0.75rem;
    font-family: $font-family;
    font-weight: $regular;
    line-height: 1em;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: $secondary;
    color: #fff;
    border-radius: 1.5em;
    appearance: none;
    
    @extend .tr;

    &:hover {
        background-color: darken($secondary, 10%);
    }

    &.btn--o {
        border-color: $light;
        background-color: transparent;

        &:hover {
            background-color: transparentize($light, 0.9);
        }
    }

    &.btn--i {
        color: $secondary;
        border-color: $secondary;
        background-color: transparent;

        &:hover {
            background-color: transparentize($secondary, 0.9);
        }
    }
}


.u-bg-gray-lighter {
    background-color: $gray-lighter;
}

.blocktitle {
    font-size: 1.5625rem;
    font-weight: $bold;
    text-align: center;
    color: $dark;
    //@extend .u-margin-large-bottom;
}
.blocksubtitle {
    font-size: 1.25rem;
    font-style: italic;
    text-align: center;
    margin: 1.25rem auto;
}

.txtc {text-align: center;}

.link {
    color: $secondary;
    text-decoration: underline;

    &:hover, &:focus, &:active {
        color: darken($secondary, 10%);
    }
}


/**************************************
 * THEME
 **************************************/

.pagination {
    margin-top: 30px;
    
    text-align: center;
    span, a {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.9em;
        padding: 0 10px;
        border-radius: 3px;
    }
    
    span {
        background-color: $primary;
        color: #fff;
    }
    
    a:hover {
        color: #fff;
        background-color: lighten($primary, 50%);
    }
}

.navigation {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    .menu-item {
        a {
            display: inline-block;
            line-height: 3em;
            padding: 0 1.5em;
            font-size: 0.9375rem;
            font-weight: $bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.home {
    &__wrap {
        position: relative;
        padding: 4rem 0;
        max-width: 64rem;
        margin: 0 auto;

        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        color: $secondary;
        font-size: 3.125rem;
        font-weight: $bold;
    }
    
    &__tagline {
        font-size: 1.25rem;
        color: $secondary;
        font-weight: $regular;
    }
    
    &__desc {
        font-size: 1.25rem;
        color: $gray;
        font-weight: $light;
        font-style: italic;
        max-width: 350px;
        margin-top: 6px;
    }
}

.features {
    margin: 0 auto;
}

.feat {
    text-align: center;

    &__img {
        margin-bottom: 1rem;
        height: 165px;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        &.smaller {
            height: 110px;
        }
    }

    &__count {
        display: inline-block;
        width: 5.5rem;
        height: 5.5rem;
        line-height: 5.5rem;
        font-size: 3.125rem;
        border: 2px solid $primary;
        border-radius: 50%;
        margin-bottom: 1rem;
        color: $primary;
    }

    &__title {
        max-width: 280px;
        display: inline-block;
    }
}

.logos__item {
    text-align: center;
}

.quicklink {
    background-color: rgba(255,255,255,0.3);
    padding: 0.2em 0.6em;
    border-radius: 8px;
    color: $light;
    
    &:hover {
        background-color: rgba(255,255,255,0.5);
    }
}

.logos {
    .flexgrid-3 {
        align-items: center;
    }
}

.backtotop {
    position: fixed;
    bottom: 4px;
    right: 10px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(150%);
    transition: 0.5s;

    &.visible {
        opacity: 0.7;
        transform: translateY(0%);

        &:hover, &:active {
            opacity: 1;
        }
    }
}

.domcols {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.domcol {
    flex-basis: 31%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
        height: 170px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-size: 17px;
        font-weight: 600;
        color: #333333;
        text-transform: uppercase;
        margin-top: 20px;
        flex-grow: 1;
    }

    &__text {
        flex-grow: 1;
        margin: 1rem 0 2rem;
        font-size: 15px;
        font-weight: 600;
        font-style: italic;
    }

    .btn {
        text-transform: uppercase;
    }
}


/**************************************
* UTILITIES
**************************************/

@import "u-flexgrid";
@import "u-spacing";


/**************************************
 * COMPONENTS
 **************************************/

.splash {
    @extend .dyn-cov;
    background-color: $primary;
    background-image: url(../img/bg.jpg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .logo {
        width: 312px;
    }

    .langs {
        margin-top: 40px;
    }
}

.langs {
    display: flex;

    a {
        display: block;
    }

    img {
        display: block;
        width: 50px;
        margin: 7px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .langs img {
        width: 40px;

        @include phone {
            display: none;
        }
    }
}

.logo-wrap {
    background-color: transparentize($primary, 0.1);
    padding: 37px 28px;

    @include phone {
        width: 100%;
        padding: 7px 20px;
    }

    .logo {
        width: 205px;

        @include phone {
            width: 85px;
        }
    }
}

.bg {
    @extend .dyn-cov;
    background-color: $primary;
    background-image: url(../img/bg2.jpg);
    padding-bottom: 100px;
    position: relative;

    @include phone {
        padding-bottom: 0;
    }

    .pdfs {
        @include phone {
            display: none;
        }
    }
}

.em {
    max-width: 350px;
    padding: 1rem;
    background-color: transparentize($primary, 0.1);
    color: $light;
    margin: 50px 0;
    font-size: 25px;

    @include phone {
        background-color: transparent;
        font-size: 30px;
        text-align: center;
        margin: 0 auto;
        padding: 50px 0;
    }
}

.partis {
    width: 432px;
}

.signature {
    width: 122px;
}

.flex-1 {
    flex-grow: 1;
}

.txt-bottom {
    display: flex;
    align-items: center;
    margin-top: 40px;

    @include phone {
        flex-wrap: wrap;

        > div:first-child {
            order: 3;
            margin-top: 50px;
        }

        > div:last-child {
            flex-basis: 30%;
        }
    }

    .flex-1 {
        text-align: right;
        padding: 0 28px;

        @include phone {
            flex-basis: 70%;
            padding-left: 0;
        }
    }
}

.footer {
    background-color: #B5B5B5;
    color: $light;
}

.richtext {
    p {
        font-weight: $thin;
    }

    p + p {
        margin-top: 1em;
    }

    strong {
        font-weight: $medium;
    }
}

h1 {
    font-size: 50px;
    font-weight: $bold;
    margin-bottom: 0.7em;
    color: $primary;

    @include phone {
        font-size: 35px;
    }

    span {
        font-weight: $thin;

        @include phone {
            font-size: 30px;
        }
    }
}

.pdfs {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
}

.pdf {
    display: flex;
    justify-content: stretch;
    flex-basis: 33.33%;
    margin-top: 200px;

    @include phone {
        margin-top: 0;
        border-bottom: 1px solid transparentize(#82D3DA, 0.1);
    }

    &__pic {
        background-color: transparentize(#82D3DA, 0.1);
        width: 110px; height: 102px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    &__txt {
        display: flex;
        flex-grow: 1;
        background-color: transparentize($light, 0.1);
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding-left: 12px;
        padding-right: 12px;
        color: $primary;

        strong {
            text-transform: uppercase;
        }
    }
}

.mobile {
    display: none;

    @include phone {
        display: block;
    }
}

.arrow {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 20px);
    cursor: pointer;

    @include phone {
        display: none;
    }
}